import React, { useEffect, useState } from "react";
import "./Notification.css";
import ball from "../../assets/Icon/ballIcon.svg";
import box from "../../assets/Icon/box.svg";
import doc from "../../assets/Icon/invoice.svg";
import men from "../../assets/Icon/men.svg";
import time from "../../assets/Icon/time.svg";
import x from "../../assets/Icon/x.svg";
import SingleNotification from "./SingleNotification";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import swal from "sweetalert";
import { showToast } from "../../utils/ToastHelper";
import ReactHtmlParser from "react-html-parser";
import Loader from "../CustomCommons/Loader";
import {
  can_delete_notifications,
  has_permissions,
} from "../CustomCommons/utils";
import { useHistory } from "react-router-dom";
import { logout_func2, logout_func3 } from "../Const/logoutCommon";

const Notification = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory()
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const getNotification2 = (url) => {
    // const url = `${BASE_URL}api/v1/users/notifications/?limit=50`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data.results);
        const result = res.data.data.results;
        setNotificationList(result);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if(message === 'Invalid token.' || JSON.parse(err.request.response).code === 401){
          // history.push('/login')
          logout_func2(setOnlogoutHide,history)
      }
      });
  };
  const getNotification = (url) => {
    // const url = `${BASE_URL}api/v1/users/notifications/?limit=50`;
    axios
      .get(url)
      .then((res) => {
        setIsLoading(true)
        // console.log(res.data.data.results);
        const result = res.data.data.results;
        // setNotificationList([...notificationList,result]);
        console.log('concated',[...notificationList,...result])
        setNotificationList([...notificationList,...result]);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3( history);
        }
      });
  };
  const loadMore = () =>{
    getNotification(nextUrl)
  }
  const deleteNotigication = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/users/notifications/${id}`;
        axios
          .delete(url)
          .then(() => {
            showToast("Delele", "Data Deleted");
              getNotification2(`${BASE_URL}api/v1/users/notifications/?limit=20`);
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
            if (
              message === "Invalid token." ||
              JSON.parse(err.request.response).code === 401
            ) {
              logout_func3( history);
            }
          });
        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  useEffect(() => {
    getNotification(`${BASE_URL}api/v1/users/notifications/?limit=20`);
    // deleteNotigication()
  }, []);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <div
        className="notification_main py-4"
        style={{ background: "#FAFAFA", minHeight: "100vh" }}
      >
        <div className="d-flex align-items-center mb-3">
          <img src={ball} alt="" />
          <h5 className="mt-1 ms-2 ">Notification</h5>
        </div>
        {notificationList.map((n, index) => (
          <div
            key={index}
            style={{
              border: "0.5px solid #E0E0E0",
            }}
            className=" bg-white py-4 px-2 px-md-2 px-lg-4 "
          >
            <div className="d-flex flex-column flex-md-row justify-content-between notification-box">
              <div className="d-flex ">
                <div className="box-div me-3">
                  {/* <img src={box} alt="" /> */}
                  <img src={n?.title?.includes("Customer") ? men  : n?.title?.includes("Invoice") ? doc : box } alt="" />
                </div>
                <div>
                  <div className="d-flex justify-content-between">
                    <h5 className="fw-bold">{n?.title}</h5>
                  </div>
                  {/* <p>
                    Your Inventory is Too <span className="fw-bold">Low</span>.
                    Please Support multiple stocking, distribution locations and
                    tiers
                  </p> */}
                  {ReactHtmlParser(n?.info)}
                </div>
              </div>

              <div className="d-flex align-content-start ">
                <div className="mx-2">
                  <img src={time} alt="" />
                </div>

                <p>
                  {new Date(n?.created_at).toLocaleDateString("en-US", options)}
                </p>
                {has_permissions(can_delete_notifications) ? (
                  <div
                    className="cross"
                    onClick={() => deleteNotigication(n?.id)}
                  >
                    <img src={x} alt="" />
                  </div>
                ) : (
                  <div
                    className="cross"
                    onClick={() =>
                      swal("Sorry,You are not able to delete notification.")
                    }
                  >
                    <img src={x} alt="" />
                  </div>
                )}
              </div>
            </div>{" "}
          </div>
        ))}
        {/* ==============doc=========== */}
        
        <div className="pg-div my-3 d-flex justify-content-center gap-3">
          {
            prevUrl !== null && <button onClick={()=>getNotification2(`${BASE_URL}api/v1/users/notifications/?limit=20`)} className="px-3 py-2 " style={{border:'1px solid black',outline:'none',background:'black',color:'white',borderRadius:'6px'}}>Show Less</button>
          }
          
          {
            nextUrl !== null && <button onClick={loadMore} className="px-3 py-2 " style={{border:'1px solid black',outline:'none',background:'black',color:'white',borderRadius:'6px'}}>Show More</button>
          }
          
        </div>

        {/* <SingleNotification />
        <SingleNotification />
        <SingleNotification />
        <SingleNotification /> */}
      </div>
    </div>
  );
};

export default Notification;
