import React from "react";
import plus from "../../assets/Icon/plus.svg";
import "./Customar.css";
import Select from "react-select";
import thumb1 from "../../assets/Icon/thumb1.svg";
import thumb2 from "../../assets/Icon/thumb2.svg";
import thumb3 from "../../assets/Icon/thumb3.svg";
import thumb4 from "../../assets/Icon/thumb4.svg";
import thumb5 from "../../assets/Icon/thumb5.svg";
import thumb6 from "../../assets/Icon/thumb6.svg";
import thumb7 from "../../assets/Icon/thumb7.svg";
import Paggination from "../CustomCommons/Paggination";
import ViewIcon from "../CustomCommons/ViewIcon";
import EditIcon from "../CustomCommons/EditIcon";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import { useEffect } from "react";
import { showToast } from "../../utils/ToastHelper";
import ReactPaginate from "react-paginate";
import { Link, useHistory } from "react-router-dom";
import UseData from "../Const/UseData";
import Loader from "../CustomCommons/Loader";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import NextIcon from "../CustomCommons/NextIcon";
import * as path from "../Routes/RoutePaths";
import {
  can_add_customer,
  can_change_customer,
  can_delete_customer,
  can_view_customer,
  has_permissions,
} from "../CustomCommons/utils";
import Permission from "../CustomCommons/Permission";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import kaarujLogo from "../../assets/Icon/KAARUJ 1.png";
import { logout_func2 } from "../Const/logoutCommon";

const CustomerList = () => {
  const history = useHistory();
  // const [data,loader]= UseData(`${BASE_URL}api/v1/inventory/customer/`)
  const [loader, setLoader] = useState(true);
  const [query, setQuery] = useState("");
  const [renking, setRenking] = useState("");
  const options = [
    { value: "all", label: "See All" },
    { value: "today", label: "Daily" },
    { value: "week", label: "Weekly" },
    { value: "month", label: "Monthly" },
    { value: "ascending", label: "Low To High" },
    { value: "descending", label: "High To Low" },
  ];
  // ofset code
  const [isLoading, SetisLoading] = useState(true);
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;

  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  // useEffect(() => {
  //   apiFunction(
  //     `${BASE_URL}${"api/v1/inventory/customer/"}?limit=${limit}&offset=${offset}&filter=${renking}&query=${query}`
  //   );
  // }, [renking]);
  // useEffect(() => {
  //   apiFunction(
  //     `${BASE_URL}${"api/v1/inventory/customer/"}?limit=${limit}&offset=${offset}&query=${query}`
  //   );
  // }, [query]);
  useEffect(() => {
    apiFunction(
      `${BASE_URL}${"api/v1/inventory/customer/"}?limit=${limit}&offset=${offset}&query=${query}`
    );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);

    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });

    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  useEffect(() => {
    if (query || pageNumber > 1 || renking !== "") {
      apiFunction(
        `${BASE_URL}${"api/v1/inventory/customer/"}?limit=${limit}&offset=${offset}&filter=${renking}&query=${query}`
      );
    }
  }, [renking, query]);

  // ofset code
  const updatedPageNumber = parseInt(
    localStorage.getItem("customerPageNbr")
      ? localStorage.getItem("customerPageNbr")
      : 0
  );
  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;
  const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");

  // api
  const [customarList, setCustomarList] = useState([]);

  // ---------------------------
  const getCustomerList = () => {
    const url = `${BASE_URL}api/v1/inventory/customer/?filter=${renking}&limit=${totalData}`;
    axios
      .get(url)
      .then((res) => {
        console.log("res renk", res?.data?.data?.results);
        setCustomarList(res?.data?.data?.results);

        // console.log(res.data);
        setLoader(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };

  const keys = ["name", "mobile", "email", "address", "count"];
  // total_purchase

  const getData = customarList?.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );

  const cRef = useRef();

  useEffect(() => {
    if (totalData) {
      getCustomerList();
    }
  }, [renking, totalData]);

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/inventory/customer/${id}`;
        axios
          .delete(url)
          .then(() => {
            showToast("Delele", "Data Deleted");
            getCustomerList();
            apiFunction(
              `${BASE_URL}${"api/v1/inventory/customer/"}?limit=${limit}&offset=${offset}&query=${query}`
            );
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(err.request.response);
          });
        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const handleRank = (newVal, action) => {
    // console.log(newVal.value,'----------------------newVal------------')
    setRenking(newVal.value);
  };
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Product List",
    bodyClass: "dis",
  });
  if (!has_permissions(can_view_customer)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }
  console.log("res gt", getData);

  return (
    <div>
      <div className="categorey-parent ">
        <div className="cotagorey-head customar-head mb-4">
          <div>
            <h3>Customer List </h3>
            <input
              style={{ color: "black" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) => {
                const selected = 0;
                // changePage({ selected });
                setQuery(e.target.value.toLocaleLowerCase());
                localStorage.setItem("customerPageNbr", selected);
                // (updatedPageNumber = selected)
              }}
            />
          </div>

          <div className="d-flex align-items-center">
            <div className="me-2 mb-3 mb-md-0">
              <p className="rank">Filter Rank :</p>
              <div
                className="sl"
                style={{ width: "150px", marginTop: "-15px" }}
              >
                <Select
                  onChange={handleRank}
                  defaultValue={options.slice(0, 1)}
                  options={options}
                  placeholder="---Select---"
                />
              </div>
            </div>
            {getData?.length > 0 && (query || renking) && (
              <>
                {query ? (
                  <div
                    className={`button-div  mt-4 me-2 `}
                    style={{ background: "black", cursor: "pointer" }}
                    onClick={() => HandlePrint()}
                  >
                    <span> Print Data</span>
                  </div>
                ) : renking !== "all" ? (
                  <div
                    className={`button-div  mt-4 me-2 `}
                    style={{ background: "black", cursor: "pointer" }}
                    onClick={() => HandlePrint()}
                  >
                    <span> Print Data</span>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
            {has_permissions(can_add_customer) && (
              <div
                className="button-div mt-4 "
                onClick={() => history.push(path.add_customer)}
              >
                <img src={plus} alt="" />
                <span>Add Customer</span>
              </div>
            )}
            
            <div
              className="button-div ms-2  me-2 mt-4 text-white "
              style={{ background: "black", cursor: "pointer" }}
              
            >
              {/* <span> Export</span> */}
              
              
              {
                  items_data?.length !== 0 ? <><a rel="noreferrer" style={{color:'white'}} href={`${BASE_URL}api/v1/inventory/export/customer?filter=${renking}&query=${query}`} target="_blank">Export</a></> :<><span onClick={()=> swal({
                    title:'Error!',
                    text: "There Is No Data",
                  })}> Export</span></> 
                }
             
              {/* <a
                style={{ color: "white" }}
                href={`${BASE_URL}api/v1/inventory/export/customer/`}
                target="_blank"
              >
                Export
              </a> */}
            </div>
          </div>
        </div>

        {/* customer list table */}
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my">
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "99px",
                    textAlign: "center",
                    borderTopLeftRadius: "4px ",
                  }}
                >
                  S/N
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "197px",
                  }}
                >
                  Mobile No
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "210px",
                  }}
                >
                  Customer Address
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "140px",
                  }}
                >
                  Total Purchese
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  Invoice Count
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                  }}
                >
                  Status
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "140px",
                  }}
                >
                  <p className="ms-4 my-auto">Action</p>
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {items_data.length > 0 && items_data ? (
                items_data?.map((category) => (
                  <tr className="table-row " key={category.id}>
                    <td className="text-center">{category.id}</td>
                    <td>{category.name}</td>
                    <td>{category.mobile}</td>
                    <td>
                      {category?.address
                        ? category?.address.length > 15
                          ? `${category.address.slice(0, 20)}...`
                          : category.address
                        : "N/A"}
                    </td>
                    <td>৳ {category.total_purchase}</td>
                    <td>
                      <span className="ms-2">
                        {category?.count ? category.count : 0}
                      </span>
                    </td>

                    <td className="">
                      <button
                        className={`status ${
                          category.status === true ? "c1" : "c2"
                        }`}
                        style={{ color: `${category.btnColour}` }}
                      >
                        {category.status === true ? "Active" : "Disable"}
                      </button>
                    </td>
                    <td>
                      {/* slug */}
                      <div className="d-flex gap-3">
                        <div
                          className="edit-del blue"
                          onClick={() =>
                            history.push(
                              `/customer/customer-view/${category.slug}`
                            )
                          }
                        >
                          <ViewIcon />
                        </div>
                        {has_permissions(can_change_customer) ? (
                          <div
                            className="edit-del green"
                            onClick={() =>
                              history.push(
                                `/customer/edit-customer/${category.slug}`
                              )
                            }
                          >
                            <EditIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del green"
                            onClick={() =>
                              swal(
                                "Sorry , You have not permission to change customer."
                              )
                            }
                          >
                            <EditIcon />
                          </div>
                        )}

                        {has_permissions(can_delete_customer) ? (
                          <div
                            className="edit-del red"
                            onClick={() => deleteData(category.slug)}
                          >
                            <DeleteIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del red"
                            onClick={() =>
                              swal(
                                "Sorry , You have not permission to delete customer."
                              )
                            }
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* entites and paggination */}
        {totalData > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {totalData}
                </span>
                <span className="ms-2"></span>
                Total Page:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {Math.ceil(totalData / 20)}
                </span>
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex paggination-button">
              {/* <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={
                  query?.length > 0 || getData?.length < usersPerPage
                    ? 0
                    : updatedPageNumber
                }
              /> */}

              <>
                <div className="row lead_pagination bg-transparent">
                  <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                    <nav aria-label="...">
                      <ul className="pagination pagination-sm">
                        {!isLoading ? (
                          <li className="page-item ">
                            {prevUrl !== null ? (
                              <Link
                                onClick={handlePaginationPrevious}
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on first page")
                                }
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item ">
                            <Link
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          </li>
                        )}

                        {[...Array(pageCount === null ? 1 : pageCount)].map(
                          (elem, index) => (
                            <li
                              className="page-item"
                              style={{
                                display: `${
                                  index + 1 !== pageNumber ? "none" : "block"
                                }`,
                              }}
                            >
                              {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                              {/* setPageNumber(prev=> prev - 1) */}

                              <Link
                                onClick={() =>
                                  handleChangeCurrentPage(index + 1)
                                }
                                className={`${
                                  index + 1 == pageNumber
                                    ? "paginationActive"
                                    : ""
                                } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                to="#"
                              >
                                <span className="px-1 py-1">{pageNumber}</span>
                              </Link>
                            </li>
                          )
                        )}
                        {!isLoading ? (
                          <li className="page-item">
                            {nextUrl !== null ? (
                              <Link
                                onClick={handlePaginationNext}
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />{" "}
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on last page")
                                }
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item">
                            <Link
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}

        <div style={{ display: "none" }} className="pt-4">
          <table
            style={{ marginTop: "50px", marginRight: "450px" }}
            className="table_my pdf-table my-3 pt-4 print_table"
            ref={cRef}
          >
            <thead
              className="d-none  thed w-100 text-center me-4"
              style={{ marginTop: "40px" }}
            >
              <div className="mb-4 pb-4 d-flex justify-content-center align-items-center">
                <img className="pb-3" src={kaarujLogo} width={100} alt="" />
                <p className="mt-2 ms-2">
                  Customer List Print Date : {currentDate}{" "}
                  <span className="ms-2">Total Item : {getData?.length}</span>
                  <span className="ms-2">
                    Filter By :{" "}
                    {renking === "all"
                      ? "All"
                      : renking === "today"
                      ? "Today"
                      : renking === "week"
                      ? "This Week"
                      : renking === "month"
                      ? "This Month"
                      : renking === "ascending"
                      ? "Low to high"
                      : renking === "descending"
                      ? "High to Low"
                      : ""}
                  </span>
                </p>
                {/* <p className="ms-4">
                  <span className="ms-3">Filter By : {renking}</span>
                </p> */}
              </div>
            </thead>
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "99px",
                    textAlign: "center",
                    borderTopLeftRadius: "4px ",
                  }}
                >
                  S/N
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "197px",
                  }}
                >
                  Mobile No
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "210px",
                  }}
                >
                  Customer Address
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "140px",
                  }}
                >
                  Total Purchese
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  Invoice Count
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {getData?.length > 0 ? (
                getData
                  // ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((category) => (
                    <tr className="table-row " key={category.id}>
                      <td className="text-center">{category.id}</td>
                      <td>{category.name}</td>
                      <td>{category.mobile}</td>
                      <td>
                        {category?.address
                          ? category?.address.length > 15
                            ? `${category.address}`
                            : category.address
                          : "N/A"}
                      </td>
                      <td className="">৳ {category.total_purchase}</td>
                      <td>
                        <span className="ms-2">
                          {category?.count ? category.count : 0}
                        </span>
                      </td>

                      <td className="">
                        <button
                          className={`status ${
                            category.status === true ? "c1" : "c2"
                          }`}
                          style={{ color: `${category.btnColour}` }}
                        >
                          {category.status === true ? "Active" : "Disable"}
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
