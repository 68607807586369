import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import DeleteIcon from "../../CustomCommons/DeleteIcon";
import EditIcon from "../../CustomCommons/EditIcon";
import Paggination from "../../CustomCommons/Paggination";
import ViewIcon from "../../CustomCommons/ViewIcon";
import Select from "react-select";
// import plus from "../../../assets/";
import plus from "./../../../assets/Icon/plus.svg";

import * as path from "../../Routes/RoutePaths";
import { useEffect } from "react";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import ReactPaginate from "react-paginate";
import UseData from "../../Const/UseData";
import Loader from "../../CustomCommons/Loader";
import PreviousIcon from "../../CustomCommons/PreviousIcon";
import NextIcon from "../../CustomCommons/NextIcon";
import kaarujLogo from "../../../assets/Icon/KAARUJ 1.png";
import {
  can_add_products,
  can_change_products,
  can_delete_products,
  can_view_products,
  has_permissions,
} from "../../CustomCommons/utils";
import Permission from "../../CustomCommons/Permission";
import { useReactToPrint } from "react-to-print";
import ProductPaggination from "./ProductPaggination";
import { showToast } from "../../../utils/ToastHelper";
import { logout_func2 } from "../../Const/logoutCommon";

const ProductList = () => {
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [forcePage, setForcePage] = useState(0);
  const [select, setSelect] = useState(0);
  const [query, setQuery] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [isLoading, SetisLoading] = useState(true);
  const [renking, setRenking] = useState("");
  const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
  // ofset code
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;

  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}${"api/v1/inventory/products/"}?limit=${limit}&offset=${offset}&filter=${renking}&query=${query}`
    );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);

    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  useEffect(() => {
    if (query || pageNumber > 1 || renking !== "") {
      apiFunction(
        `${BASE_URL}${"api/v1/inventory/products/"}?limit=${limit}&offset=${offset}&filter=${renking}&query=${query}`
      );
    }
  }, [renking, query]);

  // ofset code

  const options2 = [
    { value: "all", label: "See All" },
    { value: "today", label: "Daily" },
    { value: "week", label: "Weekly" },
    { value: "month", label: "Monthly" },
    { value: "year", label: "Yearly" },
  ];

  // const [data,loader] = UseData(`${BASE_URL}api/v1/inventory/products/`)
  const cRef = useRef();
  const date1Ref = useRef();
  const date2Ref = useRef();

  const [productList, setProductList] = useState([]);
  const keys = [
    "name",
    "category",
    "updated_at",
    "total",
    "total_sales",
    "stock",
    "sku",
  ];
  // const getData = productList
  const getData = productList?.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );
  const history = useHistory();
  const usersPerPage = 15;
  const updatedPageNumber = parseInt(
    localStorage.getItem("productPageNbr")
      ? localStorage.getItem("productPageNbr")
      : 0
  );
  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);

  const pagesVisited = pageNumber * usersPerPage;

  const getProduct = () => {
    let url = `${BASE_URL}api/v1/inventory/products/`;
    axios
      .get(url)
      .then((res) => {
        // console.log("productList92", res?.data.data.results);
        const result = res.data.data?.data;

        setProductList(res?.data?.data?.results);

        // SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err?.request?.response)?.message;
        // console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        console.log(err);
      });
  };
  const getProduct2 = () => {
    let url = `${BASE_URL}api/v1/inventory/products/filter/?start=${date1}&end=${date2}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data?.data;
        setProductList(
          res.data.data?.results?.sort((a, b) => b.total_sales - a.total_sales)
        );
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        // SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    getProduct();
    // date1ref.date1Ref?.value = '' ;
    // date2ref.date2Ref?.value = '' ;
    setDate1("");
    setDate2("");
  }, [renking]);

  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getProduct2();
    }
  };
  useEffect(() => {
    // getProduct();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);
  // console.log(productList,'--------------product---------');
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: 'numeric', minute: 'numeric', hour12: true
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/inventory/products/${id}`;
        axios
          .delete(url)
          .then(() => {
            // showToast("Delele", "Data Deleted");
            getProduct();
            apiFunction(
              `${BASE_URL}${"api/v1/inventory/products/"}?limit=${limit}&offset=${offset}`
            );
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });
        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };

  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  //   setSelect(selected);
  //   localStorage.setItem("productPageNbr", selected);
  // };

  useEffect(() => {
    setForcePage(0);
    localStorage.setItem("productPageNbr", forcePage);
    console.log("forcePage", forcePage);
    console.log("selected", select);
    console.log("productPageNbr", updatedPageNumber);
  }, [query]);

  // const pageCount = Math.ceil(getData?.length / usersPerPage);

  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Product List",
    bodyClass: "dis",
    onBeforePrint: () => SetisLoading(true),
    onAfterPrint: () => SetisLoading(false),
  });
  const getPrint = () => {
    HandlePrint();
  };
  if (!has_permissions(can_view_products)) {
    return <Permission />;
  }

  if (isLoading) {
    return <Loader />;
  }
  const handleRank = (newVal, action) => {
    setRenking(newVal.value);
  };
  console.log("pageCount", pageCount);
  // if (query) {
  //   updatedPageNumber = 0;
  //   localStorage.setItem("productPageNbr", 0);
  // }

  console.log("itemData", items_data);

  return (
    <div>
      <div className="categorey-parent">
        <div className="cotagorey-head mb-4">
          <div className="d-flex align-align-items-baseline">
            <div>
              <h3>Product List </h3>
              <input
                style={{ color: "black" }}
                type="text"
                className="filter-input"
                placeholder="Search here"
                onChange={(e) => {
                  const selected = 0;
                  setQuery(e.target.value.toLocaleLowerCase());
                  // changePage({ selected });
                  localStorage.setItem("productPageNbr", selected);
                }}
              />
            </div>
          </div>
          <div className="filter_div">
            <div
              className="sl mt-1"
              style={{ width: "150px", marginRight: "5px" }}
            >
              <Select
                onChange={handleRank}
                defaultValue={options2.slice(0, 1)}
                options={options2}
                placeholder="---Select---"
              />
            </div>
            <div className="my-2 my-lg-0">
              <input
                type="date"
                name="date1ref"
                format="DD-MM-YYYY"
                className="date-input"
                ref={date1Ref}
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                name="date2ref"
                ref={date2Ref}
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div
              className="button-div my-auto mx-2 "
              style={{ background: "black", cursor: "pointer" }}
              onClick={generateData}
            >
              <span> Generate</span>
            </div>
            {totalData > 0 && (
              <div
                className="button-div my-auto me-2  text-white "
                style={{ background: "black", cursor: "pointer" }}
                onClick={() => getPrint()}
              >
                <span> Print</span>
              </div>
            )}

            {has_permissions(can_add_products) && (
              <div
                className="button-div"
                onClick={() => history.push(path.add_product)}
              >
                <img src={plus} alt="" />
                <span>Add Product</span>
              </div>
            )}

            {/* ${BASE_URL}${"api/v1/inventory/products/"}?filter=${renking}&query=${query}&start=${date1}&end=${date2} */}
            {/* ${BASE_URL}api/v1/inventory/products/filter/? */}
            <div
              className="button-div ms-2 my-auto me-2  text-white "
              style={{ background: "black", cursor: "pointer" }}
            >
              {items_data?.length !== 0 ? (
                <>
                  <a
                    style={{ color: "white" }}
                    href={`${BASE_URL}api/v1/inventory/export/products?filter=${renking}&query=${query}&start=${date1}&end=${date2}`}
                    target="_blank"
                  >
                    Export
                  </a>
                </>
              ) : (
                <>
                  <span
                    onClick={() =>
                      swal({
                        title: "Error!",
                        text: "There Is No Data",
                      })
                    }
                  >
                    {" "}
                    Export
                  </span>
                </>
              )}
              {/*  */}
            </div>
          </div>
        </div>
        {/* categorey list table */}
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my pdf-table my-3">
            {/* <thead
              className="d-none  thed w-100 text-center me-4"
              style={{ marginTop: "40px" }}
            >
              <div className="mb-4 pb-4 d-flex justify-content-center align-items-center">
                <img className="pb-3" src={kaarujLogo} width={150} alt="" />
                <p className="mt-2 ms-4">
                  Print Date : {currentDate}{" "}
                  <span className="ms-3">Page Number : {pageNumber + 1}</span>
                </p>
              </div>
            </thead> */}
            <thead
              className="bg-dark text-white head-row mt-3 main-head-print"
              style={{ height: "50px" }}
            >
              <tr className="">
                {/* <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "99px",
                    textAlign: "center",
                    borderTopLeftRadius: "4px ",
                  }}
                >
                  S/N
                </th> */}
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                  }}
                >
                  <p className="ms-3 my-auto">Thumbnail</p>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  SKU
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Category
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Last Modified
                </th>
                {/* <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Total
                </th> */}
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Stock
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "90px",
                  }}
                >
                  Total Sale
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "90px",
                  }}
                >
                  Status
                </th>

                <th
                  className="action"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "135px",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {items_data.length > 0 && items_data ? (
                items_data.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    {/* <td className="text-center">{category?.slug}</td> */}
                    <td>
                      <img
                        width={50}
                        style={{ margin: "6px 0px !important" }}
                        height={50}
                        src={`${category.thumb}`}
                        alt=""
                        className="ms-3"
                      />
                    </td>
                    <td>
                      {/* {category.name.length > 15
													? `${category.name.slice(0, 15)}...`
													: category.name.slice(0, 15)} */}
                      {category.name}
                    </td>
                    <td>{category?.sku ? category?.sku : "N/A"}</td>
                    <td>{category.category ? category?.category : "N/A"}</td>

                    <td>
                      {new Date(category.updated_at).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>
                    {/* <td>{category.total}</td> */}
                    <td>{category.stock}</td>
                    <td>{category.total_sales}</td>

                    <td>
                      <button
                        className={`status ${
                          category.is_active === true ? "c1" : "c2"
                        }`}
                        style={{ color: `${category.btnColour}` }}
                      >
                        {category.is_active === true ? "Active" : "Disable"}
                      </button>
                    </td>
                    <td className="action">
                      <div className="d-flex gap-3">
                        <div
                          className="edit-del blue"
                          onClick={() =>
                            history.push(
                              `/inventory/product-view/${category?.slug}`
                            )
                          }
                        >
                          <ViewIcon />
                        </div>
                        {has_permissions(can_change_products) ? (
                          <div
                            className="edit-del green"
                            onClick={() => {
                              history.push(
                                `/inventory/edit-product/${category?.slug}`
                              );
                            }}
                          >
                            <EditIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del green"
                            onClick={() =>
                              swal("Sorry, You are not able to edit product.")
                            }
                          >
                            <EditIcon />
                          </div>
                        )}

                        {has_permissions(can_delete_products) ? (
                          <div
                            className="edit-del red"
                            onClick={() => deleteData(category?.slug)}
                          >
                            <DeleteIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del red"
                            onClick={() =>
                              swal("Sorry , You are not able to delete product")
                            }
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* entites and paggination */}
        <div style={{ display: "none" }} className="pt-4">
          <table
            style={{ marginTop: "50px", marginRight: "600px" }}
            className="table_my pdf-table my-3 pt-4 print_table"
            ref={cRef}
          >
            <thead
              className="d-none  thed w-100 text-center me-4"
              style={{ marginTop: "40px" }}
            >
              <div className="mb-4 pb-4 d-flex justify-content-center align-items-center">
                <img className="pb-3" src={kaarujLogo} width={100} alt="" />
                <p className="mt-2 ms-4">
                  Product List Print Date : {currentDate}{" "}
                  <span className="ms-3">
                    Total Item : {productList?.length}
                  </span>
                  <span className="ms-2">
                    Filter By :{" "}
                    {renking === "all"
                      ? "All"
                      : renking === "today"
                      ? "Today"
                      : renking === "week"
                      ? "This Week"
                      : renking === "month"
                      ? "This Month"
                      : renking === "year"
                      ? "This Year"
                      : ""}
                  </span>
                </p>
              </div>
            </thead>
            <thead
              className="bg-dark text-white head-row mt-3 main-head-print"
              style={{ height: "50px" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                  }}
                >
                  <p className="ms-3 my-auto">Thumbnail</p>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  SKU
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Category
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Last Modified
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Total
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Stock
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "140px",
                  }}
                >
                  Total Sale
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "141px",
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {totalData > 0 ? (
                productList.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    {/* <td className="text-center">{category?.slug}</td> */}
                    <td>
                      <img
                        width={50}
                        style={{ margin: "6px 0px !important" }}
                        height={50}
                        src={`${category.thumb}`}
                        alt=""
                        className="ms-3"
                      />
                    </td>
                    <td>{category.name}</td>
                    <td>{category?.sku ? category?.sku : "N/A"}</td>
                    <td>{category.category ? category?.category : "N/A"}</td>

                    <td>
                      {new Date(category.updated_at).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>
                    <td>{category.total}</td>
                    <td>{category.stock}</td>
                    <td>{category.total_sales}</td>

                    <td>
                      <button
                        className={`status ${
                          category.is_active === true ? "c1" : "c2"
                        }`}
                        style={{ color: `${category.btnColour}` }}
                      >
                        {category.is_active === true ? "Active" : "Disable"}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {console.log(totalData, "total item")}
        {totalData > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {totalData}
                </span>
                <span className="ms-2"></span>
                Total Page:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {Math.ceil(totalData / 20)}
                </span>
              </p>
            </div>
            {/* <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={query?.length > 0 ? 0 : updatedPageNumber}
              />
            </div> */}
            <div className="col-12 col-md-6 d-flex paggination-button">
              <>
                <div className="row lead_pagination bg-transparent">
                  <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                    <nav aria-label="...">
                      <ul className="pagination pagination-sm">
                        {!isLoading ? (
                          <li className="page-item ">
                            {prevUrl !== null ? (
                              <Link
                                onClick={handlePaginationPrevious}
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on first page")
                                }
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item ">
                            <Link
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          </li>
                        )}

                        {[...Array(pageCount === null ? 1 : pageCount)].map(
                          (elem, index) => (
                            <li
                              className="page-item"
                              style={{
                                display: `${
                                  index + 1 !== pageNumber ? "none" : "block"
                                }`,
                              }}
                            >
                              {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                              {/* setPageNumber(prev=> prev - 1) */}

                              <Link
                                onClick={() =>
                                  handleChangeCurrentPage(index + 1)
                                }
                                className={`${
                                  index + 1 == pageNumber
                                    ? "paginationActive"
                                    : ""
                                } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                to="#"
                              >
                                <span className="px-1 py-1">{pageNumber}</span>
                              </Link>
                            </li>
                          )
                        )}
                        {!isLoading ? (
                          <li className="page-item">
                            {nextUrl !== null ? (
                              <Link
                                onClick={handlePaginationNext}
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />{" "}
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on last page")
                                }
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item">
                            <Link
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
